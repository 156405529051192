export const VIDEOS = [
  {
    url: 'https://youtu.be/dcHFfB3RDDQ?si=avpZhWG0BUR8bZcg',
    urlEmbed: 'https://www.youtube.com/embed/dcHFfB3RDDQ?si=avpZhWG0BUR8bZcg',
    thumb: 'https://img.youtube.com/vi/dcHFfB3RDDQ/maxresdefault.jpg',
    title: 'Роман Мануйлов',
    description: 'Как Управлять Холдингом и Организовать Свою Конференцию'
  },
  {
    url: 'https://youtu.be/PAd7neKL_tI?si=ZQ-cv07Fa0gnyxaR',
    urlEmbed: 'https://www.youtube.com/embed/PAd7neKL_tI?si=ZQ-cv07Fa0gnyxaR',
    thumb: 'https://img.youtube.com/vi/PAd7neKL_tI/maxresdefault.jpg',
    title: 'Сергей TEMZA',
    description: 'Путь в гемблинг, стримы, сверхдоходы'
  },
  {
    url: 'https://youtu.be/CD670oUAQkE?si=MHf-CAXVpx4lVIYt',
    urlEmbed: 'https://www.youtube.com/embed/CD670oUAQkE?si=MHf-CAXVpx4lVIYt',
    thumb: 'https://img.youtube.com/vi/CD670oUAQkE/maxresdefault.jpg',
    title: 'Иванов',
    description: 'Как стать самым популярным CPA инфлюенсером?'
  },
  {
    url: 'https://youtu.be/ox0bA6GBfpg?si=tOD-DIorfo5Kjyxx',
    urlEmbed: 'https://www.youtube.com/embed/ox0bA6GBfpg?si=tOD-DIorfo5Kjyxx',
    thumb: 'https://img.youtube.com/vi/ox0bA6GBfpg/maxresdefault.jpg',
    title: 'Додепов',
    description: 'Оффлайн стримы и карьера'
  },
  {
    url: 'https://youtu.be/ZJ6v4Ra-DlM?si=Ck7UsYrlX9nuMH1y',
    urlEmbed: 'https://www.youtube.com/embed/ZJ6v4Ra-DlM?si=Ck7UsYrlX9nuMH1y',
    thumb: 'https://img.youtube.com/vi/ZJ6v4Ra-DlM/maxresdefault.jpg',
    title: 'Михаил Дубовой',
    description: 'От офлайн-бизнеса до успешного арбитража трафика'
  },
  {
    url: 'https://youtu.be/_x9ahsNqHko?si=zGEmUZP0AHsRGX80',
    urlEmbed: 'https://www.youtube.com/embed/_x9ahsNqHko?si=zGEmUZP0AHsRGX80',
    thumb: 'https://img.youtube.com/vi/_x9ahsNqHko/maxresdefault.jpg',
    title: 'Лорд Трепутин',
    description: 'Стримы, казино, бизнес, успехи'
  },
  {
    url: 'https://youtu.be/eiHxlVyGs9U?si=bxqYM9WVhoKmHSbm',
    urlEmbed: 'https://www.youtube.com/embed/eiHxlVyGs9U?si=bxqYM9WVhoKmHSbm',
    thumb: 'https://img.youtube.com/vi/eiHxlVyGs9U/maxresdefault.jpg',
    title: 'Денис Дзюбенко',
    description: 'Правда о конференции SiGMA'
  },
  {
    url: 'https://youtu.be/3-LThlg_upA?si=BrpzBR7C2P5cPyss',
    urlEmbed: 'https://www.youtube.com/embed/3-LThlg_upA?si=x1BiD1TkF4tWnqoj',
    thumb: 'https://img.youtube.com/vi/3-LThlg_upA/maxresdefault.jpg',
    title: 'Артем Прокофьев',
    description: 'Gambling Pro, проблемы разработки, УБТ'
  },
  {
    url: 'https://youtu.be/uuPBjCYFZF8?si=veqhBL2Sdry92zG6',
    urlEmbed: 'https://www.youtube.com/embed/uuPBjCYFZF8?si=dI_WTImtF-U0w0pw',
    thumb: 'https://img.youtube.com/vi/uuPBjCYFZF8/maxresdefault.jpg',
    title: 'Александр Дужников',
    description: 'BROCONF, арбитраж, бизнес в США.'
  },
  {
    url: 'https://www.youtube.com/embed/pDqDNIdAmwc',
    urlEmbed: 'https://www.youtube.com/embed/pDqDNIdAmwc',
    thumb: 'https://img.youtube.com/vi/pDqDNIdAmwc/maxresdefault.jpg',
    title: 'Наиль Байков',
    description: 'Какие проблемы часто возникают на конференциях и среди спикеров?'
  },
  {
    url: 'https://www.youtube.com/watch?v=1auECQloaz4',
    urlEmbed: 'https://www.youtube.com/embed/1auECQloaz4?si=-vh5wPzOBOuaDaNP',
    thumb: 'https://img.youtube.com/vi/1auECQloaz4/maxresdefault.jpg',
    title: 'VASKOV',
    description: 'Гость нашего выпуска -  Стример Васьков VASKOV. Первый народный!'
  },
  {
    url: 'https://www.youtube.com/watch?v=V9Mg1fDamS4',
    urlEmbed: 'https://www.youtube.com/embed/V9Mg1fDamS4?si=Db3Svu0xo7oyDFDU',
    thumb: 'https://img.youtube.com/vi/V9Mg1fDamS4/maxresdefault.jpg',
    title: 'Саша Уснул',
    description: 'Как партнерки и CPA-сетки продвигают себя, как развивается Youtube-направление и в целом медийка'
  },
  {
    url: 'https://www.youtube.com/watch?v=_pofQddb0lw',
    urlEmbed: 'https://www.youtube.com/embed/_pofQddb0lw?si=84eBMUf8pDQyo6jW',
    thumb: 'https://img.youtube.com/vi/_pofQddb0lw/maxresdefault.jpg',
    title: 'Belatra Games',
    description: 'Кто и как создаёт ваши любимые слоты? Узнали всё, об играх в казино.'
  },
  {
    url: 'https://www.youtube.com/watch?v=vmNxc7clTtI',
    urlEmbed: 'https://www.youtube.com/embed/vmNxc7clTtI?si=QsiLSpTB0RFReM_F',
    thumb: 'https://img.youtube.com/vi/vmNxc7clTtI/maxresdefault.jpg',
    title: 'STRIJ',
    description: 'SEO и голод кадров, флаг гемблинга, профсоюз аффилейтов.'
  },
  {
    url: 'https://www.youtube.com/watch?v=lDSSFLm0hQ0',
    urlEmbed: 'https://www.youtube.com/embed/lDSSFLm0hQ0?si=rpZ_KQP18zioGxZ2',
    thumb: 'https://img.youtube.com/vi/lDSSFLm0hQ0/maxresdefault.jpg',
    title: 'Эрдэм, Head of Influence в Royal Partners',
    description: 'Человек, который подписал LuxuryGirl на стриминг, главный советчик MELLSTROY, а также друг Егора Крида по переписке.'
  }
]

export const FOOTER_LEFT_ICONS = [
  {
    url: 'https://www.youtube.com/@royalpartnerscis',
    icon: 'youtube'
  },
  {
    url: 'https://www.instagram.com/troe_pro_roi/',
    icon: 'instagram'
  },
  {
    url: 'https://t.me/troe_pro_roi',
    icon: 'telegram'
  }
]

export const FOOTER_RIGHT_ICONS = [
  {
    url: 'https://t.me/jooxlan',
    icon: 'telegram'
  },
  {
    url: 'mailto:m.drozdov@jetmail.cc',
    icon: 'mail'
  }
]
