<template>
  <div
    class="landing"
    :class="{ 'landing_modal-opened': showModal }"
  >
    <Header
      class-name="landing__header"
      :videos="videos"
      :open-modal="openModal"
      :icons="leftIcons"
    />
    <main class="main">
      <div class="container">
        <Hero class-name="landing__hero" />
        <div class="landing__videos">
          <VideoItem
            class-name="landing__video"
            :video="video"
            :is-first="index === 0"
            v-for="(video, index) in videos"
            :key="video.url"
            @click.prevent="openModal(video)"
          />
        </div>
        <More
          v-if="!showAll"
          class-name="landing__more-videos"
          @click.prevent="showAll = !showAll"
        />
      </div>
    </main>
    <FooterBlock
      class-name="landing__footer"
      :left-icons="leftIcons"
      :right-icons="rightIcons"
    />
    <ModalShadow
      v-if="showModal"
      :url="openedVideo.urlEmbed"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Hero from '@/components/Hero/Hero.vue'
import VideoItem from '@/components/Video/Video.vue'
import More from '@/components/More/More.vue'
import FooterBlock from '@/components/Footer/Footer.vue'
import ModalShadow from '@/components/ModalShadow/ModalShadow.vue'
import { VIDEOS, FOOTER_LEFT_ICONS, FOOTER_RIGHT_ICONS } from './constants'

export default {
  name: 'LandingComponent',
  data: () => {
    return {
      showModal: false,
      openedVideo: null,
      showAll: false
    }
  },
  components: {
    ModalShadow,
    More,
    Hero,
    Header,
    VideoItem,
    FooterBlock
  },
  computed: {
    videos  () {
      return this.showAll ? VIDEOS : VIDEOS.slice(0, 4)
    }
  },
  created () {
    this.leftIcons = FOOTER_LEFT_ICONS
    this.rightIcons = FOOTER_RIGHT_ICONS
  },
  methods: {
    openModal (video) {
      if (window.innerWidth > 600) {
        this.openedVideo = video
        this.showModal = true

        return
      }

      window.open(video.url)
    }
  }
}
</script>

<style lang="scss" src="./Landing.scss"></style>
