<template>
  <div class="hero" :class="className">
    Подкаст <span class="hero__trio">«Трое про ROI»</span> создан для того, чтобы освещать события арбитража и гемблинга.
    Мы приглашаем <span class="hero__experts">экспертов</span> мира iGaming и
    <img src="@/assets/img/glass.webp" alt="glass" class="hero__glass">
    знатоков отрасли, чтобы обсудить самые актуальные <span class="hero__trends">темы и тренды.</span>
  </div>
</template>

<script>
export default {
  name: 'HeroComponent',
  props: {
    className: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" src="./Hero.scss" />
