<template>
  <header class="header" :class="className">
    <div class="header__container">
      <div class="header__logo-container">
        <img src="@/assets/img/logo.webp" alt="logo" class="header__logo">
      </div>

      <a
        v-if="videos && videos.length"
        target="_blank"
        :href="videos[0].url"
        class="header__last-video button"
        @click.prevent="openModal(videos[0])"
      >
        Смотри последний выпуск
      </a>
      <Icons class="header__icons" :icons="icons" />
      <div class="header__persons-container">
        <img src="@/assets/img/persons.webp" alt="" class="header__persons">
      </div>
    </div>
    <Form class="header__form" />
    <img src="@/assets/img/person1.webp" alt="" class="header__person header__person_1">
    <img src="@/assets/img/person2.webp" alt="" class="header__person header__person_2">
  </header>
</template>

<script>
import Icons from '@/components/Icons/Icons.vue'
import Form from '@/components/Form/Form.vue'

export default {
  name: 'HeaderComponent',
  props: {
    videos: {
      type: Array,
      default: () => []
    },
    className: {
      type: String,
      default: ''
    },
    openModal: {
      type: Function,
      default: () => {}
    },
    icons: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Form,
    Icons
  }
}
</script>

<style lang="scss" src="./Header.scss" scoped></style>
