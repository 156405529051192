<template>
  <footer class="footer" :class="className">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__logo-container">
          <img src="@/assets/img/logo.webp" alt="logo" class="footer__logo">
        </div>
        <FooterSide
          class-name="footer__side"
          :icons="leftIcons"
          title="Слушай нас здесь"
        />
        <FooterSide
          class-name="footer__side"
          :icons="rightIcons"
          title="Приходи к нам на подкаст"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import FooterSide from '@/components/FooterSide/FooterSide.vue'

export default {
  name: 'FooterComponent',
  components: { FooterSide },
  props: {
    className: {
      type: String,
      default: ''
    },
    leftIcons: {
      type: Array,
      default: () => []
    },
    rightIcons: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" src="./Footer.scss" />
