<template>
  <div class="modal-shadow" @click.self="closeModal">
    <div class="modal-close" @click="closeModal">&#10006;</div>
    <div class="modal">
      <slot name="title"></slot>
      <slot name="body">
        <iframe
          :src="url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </slot>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalShadow',
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" src="./ModalShadow.scss" />
